import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import Autocomplete from 'react-google-autocomplete';
import config from '../../../utils/config';

type Props = {
  options: RegisterOptions;
  name: string;
  className?: string;
  errors?: FieldErrors | undefined;
  control: any;
  type?: string;
  placeholder?: string;
}

const PlaceInput = ({ control, options, name, className, errors, placeholder, ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        rules={options}
        render={({ field }) => (
          <Autocomplete
            // onBlur={field.onBlur}
            className={styles.selectContainer}
            options={{
              types: ['address'],
            }}
            // apiKey={config.googleApiKey}
            onPlaceSelected={(value) => {
              console.log('value', value);
              field.onChange(value);
              field.onBlur();
            }}
            placeholder={field.value?.street ? `${field.value.number || ''} ${field.value.street}, ${field.value.zip_code} ${field.value.city}`: placeholder}
          />
        )}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default PlaceInput
