import homeBg from '../../assets/Othr-fond-web.jpg';
import standardBg from '../../assets/FOND_OTHRS.jpg';
import styles from './style.module.scss';
import { PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const Background = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const homePages = [
    '/',
    '/othrs',
    '/events',
    '/groups',
    '/media',
  ]
  const backgroundImage = useMemo(() => {
    if (homePages.includes(location.pathname)) return homeBg;
    return standardBg;
  }, [ location ]);

  return (
    <div className={styles.container}>
      <img src={backgroundImage} className={styles.image} alt={'background'} />
      {children}
    </div>
  );
};

export default Background;
