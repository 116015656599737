import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import { ChangeEvent, useRef, useState } from 'react';
import videoFileIcon from '../../../assets/fileIcon.svg';
import audioFileIcon from '../../../assets/audioFileIcon.svg';
import { fileSize } from '../../../utils/misc';

type Props = {
  options: RegisterOptions;
  name: string;
  className?: string;
  errors?: FieldErrors | undefined;
  control: any;
  mimeType: string;
}

const PlaceInput = ({ control, options, name, className, errors, mimeType, ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  const [ filename, setFilename ] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, onChange: Function, onBlur: Function) => {
    const input = event.target;
    if (input.files && input.files?.[0]) {
      const file = input.files![0];
      const url = URL.createObjectURL(file);
      const blob = await (await fetch(url)).blob();
      onChange(blob);
      onBlur();
      setFilename(`${file.name} (${fileSize(blob.size)})`);
    }
  };

  const showFileDialog = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        rules={options}
        render={({ field }) => (
          <div className={styles.filePicker} onClick={showFileDialog} >
            <div className={styles.content}>
              <img src={mimeType.includes('video') ? videoFileIcon : audioFileIcon} className={styles.fileIcon} alt={'addImage'}/>
              <span className={styles.placeholder}>{filename || rest.placeholder}</span>
            </div>
            <input
              style={{ visibility: 'hidden', position: 'absolute' }}
              type={'file'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleFileChange(e, field.onChange, field.onBlur);
              }}
              accept={mimeType}
              ref={fileInputRef}
            />
          </div>
        )}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default PlaceInput;
