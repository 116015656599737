import { SubmitHandler, useForm } from 'react-hook-form';
import { useErrorModalContext } from '../../contexts/ErrorModalContext';
import styles from './style.module.scss';
import SectionTitle from '../../components/form/sectionTitle';
import TextInput from '../../components/form/textInput';
import ColoredButton from '../../components/buttons/coloredButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { editPassword } from '../../store/slices/account';
import { useValidationModalContext } from '../../contexts/ValidationModalContext';

interface PasswordInput {
  password: string,
  newPassword: string,
  confirmNewPassword: string,
}

const PasswordForm = () => {
  const editPasswordPending = useAppSelector(state => state.account.editPasswordPending);
  const { setError } = useErrorModalContext();
  const dispatch = useAppDispatch();
  const { setText } = useValidationModalContext();

  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm<PasswordInput>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });
  const onSubmitPassword: SubmitHandler<PasswordInput> = async (data: PasswordInput) => {
    try {
      await dispatch(editPassword(data.newPassword)).unwrap();
      reset();
      setText('Votre mot de passe a bien été modifié');
    } catch (err) {
      setError(`Votre mot de passe n\'a pas pu être modifié`);
    }
  }
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmitPassword)}>
      <SectionTitle title={'Modifier mon mot de passe'}/>
      <TextInput type={'password'} name={'password'} register={register} placeholder={'Mot de passe actuel'} errors={errors} options={{ required: { value: true, message: 'Champ requis' } }}/>
      <TextInput type={'password'} name={'newPassword'} register={register} placeholder={'Nouveau mot de passe'} errors={errors} options={{ required: { value: true, message: 'Champ requis' } }}/>
      <TextInput
        type={'password'}
        name={'confirmNewPassword'}
        register={register}
        placeholder={'Confirmation nouveau mot de passe'}
        errors={errors}
        options={{ required: { value: true, message: 'Champ requis' }, validate: () => (
          getValues('newPassword') === getValues('confirmNewPassword') ? true : 'Les mots de passe ne correspondent pas'
        )}}
      />
      <ColoredButton loading={editPasswordPending} text={'Modifier mon mot de passe'} type={'submit'}/>
    </form>
  );
};

export default PasswordForm;
