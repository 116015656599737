import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import Select, { SingleValue } from 'react-select';

interface Option {
  value: string,
  label: string,
}

type Props = {
  options: RegisterOptions;
  name: string;
  className?: string;
  errors?: FieldErrors | undefined;
  control: any;
  stringOptions: string[];
}

const SelectInput = ({ control, stringOptions, options, name, className, errors, ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  const selectOptions: Option[] = stringOptions.map((str) => ({
    label: str,
    value: str,
  }))

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            isClearable={false}
            onChange={(newValue : SingleValue<Option>) => {
              if (!newValue) return;
              field.onChange(newValue.value);
            }}
            onBlur={field.onBlur}
            value={field.value ? {
              value: field.value,
              label: field.value,
            } : null}
            options={selectOptions}
            unstyled={true}
            maxMenuHeight={150}
            placeholder={rest.placeholder}
            classNames={{
              control: () => styles.selectControl,
              valueContainer: () => styles.selectValueContainer,
              indicatorSeparator: () => styles.selectIndicatorSeparator,
              indicatorsContainer: () => styles.selectIndicatorsContainer,
              placeholder: () => styles.selectPlaceholder,
              menu: () => styles.selectMenu,
              menuList: () => styles.selectMenuList,
              option: () => styles.selectOption,
            }}
          />
        )}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default SelectInput;
